'use client'

import { useMemo } from 'react'
import { parseCookies } from '../utils'
import { experimentSectionByVariation } from 'src/common/analytics/consts'

export const useServerSideExperiments = () => {
  const cookieStr = parseCookies(global.window ? document.cookie : '')
  const experiment = useMemo(() => {
    try {
      const experiments = JSON.parse(cookieStr?.experiments ?? '[]')

      return experiments.reduce(
        (prev, current) => ({
          ...prev,
          [current.experiment_id]: {
            experimentSection:
              experimentSectionByVariation[current.experiment_id],
            variation: current.variation,
          },
        }),
        {}
      )
    } catch {
      return {}
    }
  }, [cookieStr])

  return experiment
}
